import React, { useReducer, useContext, useEffect } from 'react';
import {
  deleteCandidate,
  updateCandidate,
} from '../../../api/endpoints/candidate';
import { Button, InputFieldWithLabel } from '../../../components';
import { Grid, Row, Col } from '../../../components/Grid';
import Icon from '../../../components/Icon';
import LogoutButton from '../../../components/LogoutButton';
import { useAuth0 } from '@auth0/auth0-react';
import {
  StateUserContext,
  DispatchUserContext,
} from '../../../context/UserContext';

import { redirectAfterLogout } from '../../../utils/redirectUrls';
import { localClearAll } from '../../../utils/localSave';
import { useTranslation } from 'react-i18next';
import { Typography } from '../../../components/Typography';
import ApplicationList from '../../components/ApplicationList';

const accountReducer = (state, action) => {
  switch (action.type) {
    case 'populateForm': {
      return {
        ...state,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        email: action.payload.email,
        phoneNumber: action.payload.phoneNumber,
        country: action.payload.country,
        city: action.payload.city,
      };
    }
    case 'field': {
      const { name, value } = action.payload;
      return {
        ...state,
        [name]: value,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const Account = () => {
  const { user } = useContext(StateUserContext);
  const userDispatch = useContext(DispatchUserContext);
  const { logout } = useAuth0();

  const { t } = useTranslation();

  const [state, dispatch] = useReducer(accountReducer, {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    country: '',
    city: '',
    isLoading: true,
    error: '',
  });

  useEffect(() => {
    dispatch({ type: 'populateForm', payload: user });
  }, [user]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const submitData = async () => {
      try {
        const request = {
          body: state,
        };
        await updateCandidate(request);
        //update user object
        const { isLoading, error, email, ...rest } = state;

        userDispatch({
          type: 'userUpdate',
          payload: rest,
        });
      } catch (err) {
        //handle error
      }
    };
    submitData();
  };

  /* update values from changed fields */
  const handleChange = (e) => {
    const name = e.target.name;
    dispatch({
      type: 'field',
      payload: { name: name, value: e.target.value },
    });
  };

  return (
    <Grid>
      <Row>
        <Col>
          <Typography mb={40} tag="h1">{t("applicant.myAccount", "My account")}</Typography>
        </Col>
      </Row>
      <Row>
        <Col size={16} break="md">
          <form onSubmit={handleSubmit}>
            <Row>
              <Col size={12} break="lg">
                <InputFieldWithLabel
                  value={state.firstName || ''}
                  name="firstName"
                  onChange={handleChange}
                  type="text"
                  required
                  id="firstName"
                  label={t('common.first_name', "First name")}
                  placeholder={t('common.first_name', "First name")}
                  mb={20}
                />
              </Col>
              <Col size={12} break="lg">
                <InputFieldWithLabel
                  value={state.lastName || ''}
                  name="lastName"
                  onChange={handleChange}
                  type="text"
                  required
                  id="lastName"
                  label={t('common.last_name', "Last name")}
                  placeholder={t('common.last_name', "Last name")}
                  mb={20}
                />
              </Col>
            </Row>

            <Row>
              <Col size={12} break="lg">
                <InputFieldWithLabel
                  value={state.email || ''}
                  name="email"
                  onChange={handleChange}
                  type="email"
                  disabled
                  required
                  id="email"
                  label={t('common.email', "Email")}
                  placeholder={t('common.email', "Email")}
                  mb={20}
                />
              </Col>
              <Col size={12} break="lg">
                <InputFieldWithLabel
                  value={state.phoneNumber || ''}
                  name="phoneNumber"
                  onChange={handleChange}
                  type="text"
                  required
                  id="phoneNumber"
                  label={t('common.phone_number', "Phone number")}
                  placeholder={t('common.phone_number', "Phone number")}
                  mb={20}
                />
              </Col>
            </Row>

            <Row>
              <Col size={12} break="lg">
                <InputFieldWithLabel
                  value={state.country || ''}
                  name="country"
                  onChange={handleChange}
                  type="text"
                  required
                  id="country"
                  label={t('common.country', "Country")}
                  placeholder={t('common.country', "Country")}
                  mb={20}
                />
              </Col>
              <Col size={12} break="lg">
                <InputFieldWithLabel
                  value={state.city || ''}
                  name="city"
                  onChange={handleChange}
                  type="text"
                  required
                  id="city"
                  label={t('common.city', "City")}
                  placeholder={t('common.city', "City")}
                  mb={20}
                />
              </Col>
            </Row>
          </form>
          <ApplicationList />
        </Col>
        <Col size={1} collapse="md"></Col>
        <Col size={7} break="md">
          <Row mt={20}>
            <Col right>
              <Button expand blue bold secondary onClick={handleSubmit}>
                <Icon invert icon="save" />
                <span>{t('page.candidate_account.save_changes')}</span>
              </Button>
            </Col>
          </Row>

          <Row mt={20}>
            <Col size={12} break="lg" m="lg-mb-20">
              <LogoutButton expand tertiary>
                {t('common.logout', "Logout")}
              </LogoutButton>
            </Col>
            <Col size={12} break="lg" m="lg-mb-20">
              <Button
                expand
                tertiary
                onClick={async () => {
                  let confirmed = window.confirm(
                    t('common.delete_account_notice')
                  );
                  if (confirmed) {
                    await deleteCandidate();
                    localClearAll();
                    logout(redirectAfterLogout);
                  }
                }}
              >
                {t('common.delete_account', "Delete account")}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Grid>
  );
};

export default Account;
