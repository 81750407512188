import axios from "axios";
import { parseRequest } from "../utilities/queries";
import { errorResponseHandler, responseHandler } from "../utilities/response";

export const getLinkedIn = async (request = {}) => {
  const { body } = parseRequest(request);
  try {
    const data = await axios.put(`/linkedin_auth/`, body);
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};
