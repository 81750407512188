import React, { createContext, useReducer } from "react";
import cleanObject from "../../../utils/cleanObject";
import { initialApplication, initialExperience } from "./applicationConstants";

export const ApplicationContext = createContext();

export const applicationReducer = (state, action) => {
  switch (action.type) {
    case "init": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "application_loaded": {
      const { applicationData } = action.payload;
      return {
        ...state,
        application: {
          ...state.application,
          ...applicationData,
        },
        isSaved: true,
        isSubmitted: applicationData?.status === "submitted" ? true : false,
        isLoading: false,
      };
    }
    case "set_is_logged_in": {
      return {
        ...state,
        isLoggedIn: true,
      };
    }
    case "update_field": {
      const { name, value } = action.payload;
      let name_l1, name_l2;
      if (name.includes(".")) {
        [name_l1, name_l2] = name.split(".");
        return {
          ...state,
          isSaved: false,
          application: {
            ...state.application,
            [name_l1]: { ...state.application[name_l1], [name_l2]: value },
          },
        };
      } else {
        return {
          ...state,
          isSaved: false,
          application: {
            ...state.application,
            [name]: value,
          },
        };
      }
    }
    case "update_experience_field": {
      const { name, value, checkbox } = action.payload;
      const [expPK, expIndex, expK] = name.split(".");

      const updatedArray = state.application[expPK].map((exp, i) => {
        if (i === parseInt(expIndex)) {
          return { ...exp, [expK]: checkbox ? !exp[expK] : value };
        } else {
          return exp;
        }
      });

      return {
        ...state,
        isSaved: false,
        application: {
          ...state.application,
          [expPK]: updatedArray,
        },
      };
    }
    case "add_experience_field": {
      const name = action.payload;
      return {
        ...state,
        isSaved: false,
        application: {
          ...state.application,
          [name]: [...state.application[name], initialExperience[name]],
        },
      };
    }
    case "remove_experience_field": {
      const { name, index } = action.payload;
      return {
        ...state,
        isSaved: false,
        application: {
          ...state.application,
          [name]: state.application[name].filter((_, i) => i !== index),
        },
      };
    }
    case "set_email_existing": {
      return {
        ...state,
        dismissedEmailExisting: true,
        emailExisting: action.payload,
      };
    }
    case "upload_started": {
      return {
        ...state,
        isUploadingFile: true,
      };
    }
    case "upload_ended": {
      return {
        ...state,
        isUploadingFile: false,
      };
    }
    case "add_files": {
      return {
        ...state,
        isUploadingFile: false,
        isSaved: false,
        application: {
          ...state.application,
          files: [...state.application.files, action.payload],
          addFiles: [...state.application.addFiles, action.payload.uuid],
        },
      };
    }
    case "remove_files": {
      return {
        ...state,
        isSaved: false,
        isUploadingFile: false,
        application: {
          ...state.application,
          addFiles: state.application.addFiles.filter(
            (uuid) => uuid !== action.payload
          ),
          files: state.application.files.filter(
            (file) => file.uuid !== action.payload
          ),
          removeFiles: [...state.application.removeFiles, action.payload],
        },
      };
    }
    case "update_accept_terms": {
      return {
        ...state,
        acceptedTerms: !state.acceptedTerms,
      };
    }

    case "submit_application_init": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "submit_application_success": {
      return {
        ...state,
        isLoading: false,
        isSubmitted: true,
      };
    }

    case "set_error": {
      return {
        ...state,
        isError: true,
        isLoading: false,
        errorMessage: action.payload,
      };
    }
    //CV Parsing ( hidden )
    case "is_parsing": {
      return {
        ...state,
        isParsing: true,
      };
    }
    case "parse_finished": {
      const data = action.payload;
      //Insert parsed data, then overwrite with already entered data.
      return {
        ...state,
        application: {
          ...initialApplication.application,
          ...data,
          ...cleanObject(state.application, {
            remove: ["experience", "education"],
          }),
        },
        isParsing: false,
      };
    }
    case "parse_error": {
      return {
        ...state,
        isParsing: false,
      };
    }
    case "reset_application": {
      return {
        ...state,
        application: initialApplication,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};

export const ApplicationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(applicationReducer, initialApplication);
  return (
    <ApplicationContext.Provider value={{ state, dispatch }}>
      {children}
    </ApplicationContext.Provider>
  );
};
