import { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { giveCandidateConsent } from '../../../api/endpoints/candidate';
import { Button, Modal } from '../../../components';
import { Col, Row } from '../../../components/Grid';
import { Typography } from '../../../components/Typography';

const ConsentModal = ({ consents, setConsents }) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const handleAccept = async () => {
    setIsLoading(true);
    const response = await giveCandidateConsent({
      params: { organizationId: consents[0].id },
    });
    const { success } = response;
    if (success) {
      setIsLoading(false);
      handleCancel();
    }
  };

  const handleCancel = () => {
    const array = [...consents];
    array.shift(); //remove first elements
    setConsents(array);
  };

  return (
    <Modal>
      <Typography tag="h3" nomargin>
        {t(
          'componenet.consent_modal.header',
          'Can we keep your data?'
        )}
      </Typography>
      <Typography tag="p" mt={12}>
        <Trans
          i18nKey="component.consent_modal.text"
          organization_name={consents[0].name}
        >
          A while ago, you applied for a position at
          <strong> {{ organization_name: consents[0]?.name }}</strong>
          . We would like to ask you if we can keep your information
          in order to consider your profile for future open positions.
        </Trans>
      </Typography>

      <Row mt={40}>
        <Col vCenter>
          <Button
            urlLike
            transparent
            onClick={handleCancel}
            disabled={isLoading}
          >
            {t('common.cancel')}
          </Button>
        </Col>
        <Col right vCenter>
          <Button
            secondary
            bold
            blue
            onClick={handleAccept}
            disabled={isLoading}
          >
            {t('component.consent_modal.cta', 'You may keep my data')}
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default ConsentModal;
