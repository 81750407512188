export const getTitleAttribute = (status, t) => {
    switch (status) {
      case "draft": {
        return t("component.card.application.status_attribute_draft");
      }
      case "invited": {
        return t("component.card.application.status_attribute_invited");
      }
      case "submitted": {
        return t("component.card.application.status_attribute_submitted");
      }
      case "rejected": {
        return t("component.card.application.status_attribute_rejected");
      }
      default: {
        return "status";
      }
    }
  };

  
