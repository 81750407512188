import React from "react";
import { Icon, Version } from "../../../components";
import { Grid, Row, Col } from "../../../components/Grid";
import { StyledFooter } from "./style";
const Footer = () => {
  return (
    <Grid fluid w100>
      <Row>
        <Col>
          <StyledFooter>
            <Icon icon="logo_with_name" />
            <Version />
          </StyledFooter>
        </Col>
      </Row>
    </Grid>
  );
};

export default Footer;
