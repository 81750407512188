import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { getPublicPosition } from '../../../api/endpoints/candidate';
import { Button, Icon } from '../../../components';
import { StyledH1, StyledH3 } from '../../../components/Typography';
import { getGenericSessionStorage } from '../../../utils/localSave';
import prependUrl from '../../../utils/prependUrl';
import { getInvertedColor } from '../../../utils/various';
import CompanyLogo from '../../components/CompanyLogo';
import { StyledButtonVars } from '../Application/components/style';

import {
  StyledClosed,
  StyledLeftContainer,
  StyledRightContainer,
} from './style';

const Closed = () => {
  //LANGUAGE
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { positionId } = useParams();

  const [positionInfo, setPositionInfo] = useState(null);
  const [referrer, setReferrer] = useState(null);

  //change language to position language
  useEffect(() => {
    if (positionInfo && positionInfo.language) {
      const lang = positionInfo.language;
      i18n.changeLanguage(lang);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positionInfo]);

  useEffect(() => {
    //get local state referer if any
    setReferrer(getGenericSessionStorage('app_referrer'));

    getInfoFrom(positionId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positionId]);

  const getInfoFrom = async (position_uuid) => {
    try {
      const position = await getPublicPosition({
        selector: position_uuid,
      });
      if (position?.success) {
        if (!position.limited) {
          history.push(`/position/${positionId}/`);
        } else {
          setPositionInfo(position?.data);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  let organization_name = positionInfo?.organization?.name;
  let position_title = positionInfo?.title;

  return (
    <StyledClosed>
      <StyledButtonVars
        accentColor={positionInfo?.branding?.accentColor}
        brandColor={positionInfo?.branding?.brandColor}
      />
      <StyledLeftContainer>
        <div>
          <CompanyLogo
            name={positionInfo?.organization?.name}
            path={positionInfo?.organization?.organizationUrl}
            logoPath={positionInfo?.branding?.files[0]?.signedRequest}
          />
          <StyledH1 light customSize={42} style={{ marginTop: 50 }}>
            {t('page.closed.header', 'Closed for applications')}
          </StyledH1>
          <StyledH3 light style={{ opacity: 0.6 }}>
            {positionInfo ? (
              <Trans
                i18nKey="page.closed.text_a"
                organization_name={organization_name}
                position_title={position_title}
              >
                {{ organization_name }} is no longer accepting
                applications for the position {{ position_title }}
              </Trans>
            ) : (
              t(
                'page.closed.text_b',
                'This position is no longer open for applications'
              )
            )}
          </StyledH3>
          {(positionInfo?.organization?.organizationUrl ||
            referrer) && (
            <Button
              useStyleVars
              onClick={
                positionInfo?.organization?.organizationUrl
                  ? () =>
                      (window.location.href = prependUrl(
                        positionInfo.organization.organizationUrl
                      ))
                  : () => (window.location.href = referrer)
              }
              style={{ marginTop: 30 }}
              red
              secondary
            >
              <Icon
                icon="arrow_right"
                mr={10}
                colorStroke={getInvertedColor(
                  positionInfo?.branding?.brandColor
                )}
              />
              {positionInfo?.organization?.organizationUrl
                ? `Go to company website`
                : `Go back`}
            </Button>
          )}
        </div>
      </StyledLeftContainer>
      <StyledRightContainer>
        <Icon icon="closed_for_applications" />
        <div>
          <Icon icon="logo_with_name" />
        </div>
      </StyledRightContainer>
    </StyledClosed>
  );
};

export default Closed;
