import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Button, Icon, LoginButton, Modal } from "../../../../../components";
import { Col, Row, Grid } from "../../../../../components/Grid";
import { Typography } from "../../../../../components/Typography";
// import { setGenericSessionStorage } from "../../../../../utils/localSave";
import { ApplicationContext } from "../../ApplicationContext";

const AccountExistsModal = () => {
  const { dispatch } = useContext(ApplicationContext);
  const { t } = useTranslation();

  return (
    <Modal tiny>
      <Grid padding="32" alignCenter fluid>
        <Row>
          <Col>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 20,
              }}
            >
              <Icon icon="notice" highlightFill width={18} />
              <Typography tag="h3" nomargin>
                {t("application_form.already_have_account")}
              </Typography>
            </div>
            <Typography tag="p">
              {t("application_form.login_popup_paragraph")}
            </Typography>
          </Col>
        </Row>
        <Row mt={20}>
          <Col right>
            <Button
              onClick={() => {
                dispatch({ type: "set_email_existing", payload: false });
              }}
            >
              {t("common.dismiss")}
            </Button>
            <LoginButton tertiary primaryColor>
              {t("common.login")}
            </LoginButton>
          </Col>
        </Row>
      </Grid>
    </Modal>
  );
};

export default AccountExistsModal;
