// three legged auth flow to obtain lite profile info
import { getLinkedIn } from "../api/endpoints/linkedin_auth";

export const getAuthLinkedInUri = () => {
  const params = {
    redirect_uri: `${window.location.origin}/linkedinAuth`,
    response_type: "code",
    scope: "r_liteprofile r_emailaddress",
    client_id: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
    state: process.env.REACT_APP_LINKEDIN_STATE,
  };
  const query = querifyObject(params);
  return `https://www.linkedin.com/oauth/v2/authorization?${query}`;
};

const querifyObject = (obj) => {
  return Object.entries(obj)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join("&");
};

export const getLinkedInData = async (state, code) => {
  //check state before proceeding
  if (state !== process.env.REACT_APP_LINKEDIN_STATE || !code) {
    console.log("State does not match or no code");
    return false;
  }

  //get data from api
  const response = await getLinkedIn({ body: { code : code, redirect_uri: `${window.location.origin}/linkedinAuth`}});
  if (response.success) {
      return response.data;
  } 

  return false;
  
};
