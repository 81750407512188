import { pdfParserUpload } from "../../../../../../api/endpoints/files";
import cleanObject from "../../../../../../utils/cleanObject";

export const parseResume = async (fileUUid, dispatch) => {
  try {
    dispatch({ type: "is_parsing" });
    const request = {
      params: {
        fileUuid: fileUUid,
      },
    };
    const response = await pdfParserUpload(request);
    if (!response || !response.success) throw new Error("Error parsing file");

    const data = readyParsedDataForInsertion(response.data);
    dispatch({ type: "parse_finished", payload: data });
  } catch (err) {
    dispatch({ type: "parse_error" });
    console.log(err);
  }
};

const readyParsedDataForInsertion = (raw) => {
  raw = cleanObject(raw);
  return raw;
};
