import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getOpenApplications } from '../../../api/endpoints/candidate';
import { BoxLoader } from '../../../components';
import { Col, Row } from '../../../components/Grid';
import { Typography } from '../../../components/Typography';
import ApplicationCard from './ApplicationCard';
import { StyledApplicationList } from './style';

const ApplicationList = () => {
  const { t } = useTranslation();
  const [applications, setApplications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchApplications = async () => {
      setIsLoading(true);
      const res = await getOpenApplications({});
      const { success, data } = res;
      if (success) {
        setApplications(data);
        setIsLoading(false);
      }
    };
    fetchApplications();
  }, []);

  return (
    <>
      <Row mt={60}>
        <Col>
          <Typography tag="h1">{t("applicant.myApplications", "My applications")}</Typography>
        </Col>
      </Row>
      <Row>
        <Col>
          <StyledApplicationList>
            {isLoading && <BoxLoader />}
            {applications.length > 0 ? (
              applications.map((application) => (
                <ApplicationCard
                  key={application.id}
                  application={application}
                  setApplications={setApplications}
                />
              ))
            ) : (
              <p> {t('application_form.no_open_applications', "You have no open applications.")}</p>
            )}
          </StyledApplicationList>
        </Col>
      </Row>
    </>
  );
};

export default ApplicationList;
