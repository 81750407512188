import React from "react";
import {
  StyledPickerRow,
  StyledPickerLabels,
  PickerRadioField,
  PickerButtonRow,
  StyledPickerContainer,
  PickerRadioFieldContainer,
} from "./style";
import { InputLabel, InputGroup } from "../Input/Input";
import { convertPeriod } from "../../candidate/views/Application/components/util";

const SkillPicker = ({
  pickerValues,
  pickerName,
  pickerMessageNoValues,
  pickerLabels,
  values,
  label,
  boldLabel,
  required,
  persistAllApplicationValues,
}) => {
  return (
    <InputGroup>
      {pickerValues &&
        Array.isArray(pickerValues) &&
        pickerValues.length > 0 && (
          <InputLabel required={required} boldLabel={boldLabel}>{label}</InputLabel>
        )}
      <StyledPickerContainer>
        {pickerValues && Array.isArray(pickerValues)
          ? pickerValues.map((pickerValue, index) => {
            const convertedPickerValue = convertPeriod(pickerValue, true);
            return (
              <StyledPickerRow key={index}>
                <div id={`competency-${index}`} style={{ paddingRight: 10 }}>
                  {pickerValue}
                </div>
                <PickerButtonRow>
                  {index === 0 && (
                    <PickerLabels pickerLabels={pickerLabels} />
                  )}
                  {[1, 2, 3, 4, 5].map((n) => (
                    <PickerRadioFieldContainer key={n}>
                      <PickerRadioField
                        type="radio"
                        name={`${pickerName}.${convertedPickerValue}`}
                        key={n}
                        id={`competency-${pickerName}-${convertedPickerValue}-${n}`}
                        // value={`${n}`}
                        value={n}
                        checked={
                          pickerName === "competencies"
                            ? parseInt(
                              values.competencies[convertedPickerValue]
                            ) === n
                            : parseInt(
                              values.languages[convertedPickerValue]
                            ) === n
                        }
                        onChange={(e) => {
                          persistAllApplicationValues(e);
                        }}
                      />
                      <label
                        htmlFor={`competency-${pickerName}-${convertedPickerValue}-${n}`}
                      ></label>
                    </PickerRadioFieldContainer>
                  ))}
                  {/* <div>Picked: { JSON.stringify(values[pickerName])}</div> */}
                </PickerButtonRow>
              </StyledPickerRow>
            );
          })
          : pickerMessageNoValues || ""}
      </StyledPickerContainer>
    </InputGroup>
  );
};

export default SkillPicker;

const PickerLabels = ({ pickerLabels }) => {
  return (
    <StyledPickerLabels>
      {pickerLabels
        ? pickerLabels.map((pickerLabel, index) => (
          <div key={index}>{pickerLabel}</div>
        ))
        : ""}
    </StyledPickerLabels>
  );
};
