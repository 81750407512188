import React, { useEffect, useState } from 'react';
import { BoxLoader } from '../../../components';
import { useLocation, Redirect } from 'react-router';
import { getLinkedInData } from '../../../utils/linkedin';
import {
  getRecentVisitedApplication,
  setGenericSessionStorage,
} from '../../../utils/localSave';

const LinkedInAuth = () => {
  //code from query params
  const [data, setData] = useState(null);
  const [positionRedirectId, setpositionRedirectId] = useState(null);
  const [redirect, setRedirect] = useState(false);
  const [error, setError] = useState(false);
  const query = new URLSearchParams(useLocation().search);
  const code = query.get('code');
  const state = query.get('state');
  const link_auth_error = query.get('error');

  //get access token
  useEffect(() => {
    let isMounted = true;

    const fetchLinkedInData = async (state, code) => {
      const fetchedData = await getLinkedInData(state, code);
      let lastVisited = getRecentVisitedApplication();
      if (fetchedData) {
        if (isMounted) {
          setRedirect(true);
          setpositionRedirectId(lastVisited);
          setData(fetchedData);
          setGenericSessionStorage(
            'linkedin_data',
            JSON.stringify(fetchedData)
          );
        }
      } else {
        if (isMounted) {
          setRedirect(true);
          setpositionRedirectId(lastVisited);
          setError(true);
        }
      }
    };
    if (code && state && !data) {
      fetchLinkedInData(state, code);
    }
    /**
     * Redirect back if user cancels auth or some other linkedin auth error happens
     */
    if (link_auth_error) {
      let lastVisited = getRecentVisitedApplication();
      setError(true);
      setRedirect(true);
      setpositionRedirectId(lastVisited);
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      {error && (
        <p>An error happened. Redirecting to last seen position</p>
      )}
      {!data && !error && <BoxLoader />}
      {redirect && positionRedirectId && (
        <Redirect to={`/position/${positionRedirectId}`} />
      )}
    </>
  );
};

export default LinkedInAuth;
