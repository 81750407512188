import React, { useContext } from "react";

import { StateUserContext } from "../context/UserContext";
import { GlobalStyle } from "./style";
import { ThemeProvider } from "styled-components";
import { candidateTheme } from "./theme";

import Layout from "./Layout";
import { Loader } from "../components/";
import { ErrorProvider } from "../context/ErrorContext";
import { MessageProvider } from "../context/MessageContext";

const CandidateApp = () => {
  const { isLoading } = useContext(StateUserContext);
  return (
    <ThemeProvider theme={candidateTheme}>
      <ErrorProvider>
        <MessageProvider>
          {isLoading ? <Loader /> : <Layout />}
          <GlobalStyle />
        </MessageProvider>
      </ErrorProvider>
    </ThemeProvider>
  );
};

export default CandidateApp;

// App description
// redirect from frontpage when logged in to open application or application saved in sessionStorage
// account page with option to change user data.
// application page
// can build a dynamic form depending on the loaded position
// The topbar is display the organization from whcich the position originates
// form should validate
// form is divided into groups
// form can validate and save onBlur if logged in and validate and save to local if not logged in
