import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Prompt } from "react-router";
const NavigateAwayPrompt = ({ isSaved, isLoggedIn }) => {
  const { t } = useTranslation();
  //Handle user navigating away from unsaved content
  const promptUnsaved = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };
  useEffect(() => {
    if (!isSaved && isLoggedIn) {
      window.addEventListener("beforeunload", promptUnsaved);
    }
    return () => {
      window.removeEventListener("beforeunload", promptUnsaved);
    };
  }, [isLoggedIn, isSaved]);

  return (
    <Prompt
      when={!isSaved && isLoggedIn}
      message={t(
        "component.prompt.unsaved",
        "You have unsaved changes. Continue?"
      )}
    />
  );
};

export default NavigateAwayPrompt;
