import { checkCandidateEmail } from "../../../../api/endpoints/candidate";
import { validEmail } from "../validationSchema";

const isPersonal = [
  "firstName",
  "lastName",
  "email",
  "phoneNumber",
  "city",
  "country",
];

/** modify application / person alvalues before saving or initializing data from server
 * @param {object} values ( object )
 * @param {boolean} init ( boolean ) initializing if true
 * @return modified values ( object )
 */
export const modifyValuesBeforeInsert = (values, init) => {
  if (init) {
    const modifiedValues = {
      ...values,
      competencies: { ...modifyObjectKeys(values?.competencies, init) },
      languages: { ...modifyObjectKeys(values?.languages, init) },
    };
    return modifiedValues;
  } else {
    const personal = {};
    const application = {};

    for (const key in values) {
      if (isPersonal.indexOf(key) > -1) {
        personal[key] = values[key];
      } else {
        application[key] = values[key];
      }
    }

    const modifiedValues = {
      personal: personal,
      application: {
        ...application,
        competencies: { ...modifyObjectKeys(application?.competencies, init) },
        languages: { ...modifyObjectKeys(application?.languages, init) },
      },
    };
    return modifiedValues;
  }
};

/*
 * modify object keys ( competencies and languages )
 * @param obj ( object )  competencies / languages
 * @encode ( boolean ) if true encode period to ascii else ascii to period
 * @return modified object with keys
 */
export const modifyObjectKeys = (obj, encode) => {
  const modifiedObject = { ...obj };
  Object.keys(modifiedObject).forEach((key) => {
    if (key.includes(".") || (!encode && key.includes("%2E"))) {
      const cleanKey = convertPeriod(key, encode);
      modifiedObject[cleanKey] = modifiedObject[key];
      delete modifiedObject[key];
    }
  });
  return modifiedObject;
};

/*
 * convert period to ascii
 * @param string (string)
 * @param encide (boolean) encode or decode
 * @return encode/decoded string.
 */
export const convertPeriod = (string, encode) => {
  return encode ? string.replaceAll(".", "%2E") : string.replaceAll("%2E", ".");
};

/**
 *
 * @param {array} arr1
 * @param {array} arr2
 * @param {string} key
 * @returns new merged array
 */
export const mergeArrays = (arr1, arr2, key) => {
  for (var i = 0, l = arr1.length; i < l; i++) {
    for (var j = 0, ll = arr2.length; j < ll; j++) {
      if (arr1[i][key] === arr2[j][key]) {
        arr1.splice(i, 1, arr2[j]);
      }
    }
  }
  return arr1;
};

/**
 * Check if an email / candidate exists
 * Return false if no or candidate logged in and true if candidate exists
 * @param {string} email
 * @param {boolean} ignoreDismiss
 * @param {boolean} isLoggedIn
 * @param {boolean} dismissedEmailExisting
 * @param {function} onExists
 * @returns
 */
export const checkExistingEmail = async (
  email,
  ignoreDismiss,
  isLoggedIn,
  dismissedEmailExisting,
  onExists
) => {
  //if authenticated
  if (isLoggedIn) return false;

  //check if dismissed this session
  if (dismissedEmailExisting && !ignoreDismiss) return false;
  //check email is valid

  const emailIsValid = await validEmail(email);

  if (emailIsValid) {
    //check if email exists as candidate in db
    const request = { params: { email: email } };

    const response = await checkCandidateEmail(request);

    if (response.exists) {
      //display login popup
      if (typeof onExists === "function") onExists();
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
