import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { StatePositionContext } from "../../../../../context/PositionContext";
import { ApplicationContext } from "../../../ApplicationContext";
import { SkillPicker } from "../../../../../../components";
import { Col, Grid, Row } from "../../../../../../components/Grid";

const CompetenciesAndLanguages = () => {
  //LANGUAGE
  const { t } = useTranslation();

  //POSTION STATE
  const { position } = useContext(StatePositionContext);

  //APPLICATION STATE
  const { state, dispatch } = useContext(ApplicationContext);
  return (
    <Grid>
      <Row>
        {(position?.competencies && position?.competencies.length > 0) ? (
          <Col size={12} break="sm">
            <SkillPicker
              label={t("application_form.competencies_label")}
              boldLabel
              required
              pickerValues={position.competencies}
              pickerName="competencies"
              pickerMessageNoValues={t("application_form.competencies_none")}
              pickerLabels={[
                t("application_form.competencies_junior"),
                t("application_form.competencies_medium"),
                t("application_form.competencies_senior"),
              ]}
              values={state.application}
              persistAllApplicationValues={(e) => {
                dispatch({
                  type: "update_field",
                  payload: {
                    name: e.target.name,
                    value: e.target.value,
                  },
                });
              }}
            />
          </Col>
        ) : null}
        {(position?.languages && position?.languages.length > 0) ? (
          <Col size={12} break="sm">
            <SkillPicker
              label={t("application_form.languages_label")}
              boldLabel
              required
              pickerValues={position.languages}
              pickerName="languages"
              pickerMessageNoValues={t("application_form.languages_none")}
              pickerLabels={[
                t("application_form.languages_none_picker"),
                t("application_form.languages_native"),
              ]}
              values={state.application}
              persistAllApplicationValues={(e) => {
                dispatch({
                  type: "update_field",
                  payload: {
                    name: e.target.name,
                    value: e.target.value,
                  },
                });
              }}
            />
          </Col>
        ) : null}
      </Row>
    </Grid>
  );
};

export default CompetenciesAndLanguages;
