import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Icon, LoginButton } from '../../../../../components';
import { StateUserContext } from '../../../../../context/UserContext';
import { pageUrl } from '../../../../../utils/redirectUrls';
import { getInvertedColor } from '../../../../../utils/various';
import { StatePositionContext } from '../../../../context/PositionContext';
import { selectPageWithHeader } from '../../utils';
import BackgroundVideo from './BackgroundVideo';
import { StyledHeader } from './style';

const CareerPageHeader = () => {
  const { t } = useTranslation();

  //LOGGED IN STATE
  const { isAuthenticated } = useContext(StateUserContext);

  //POSITION STATE
  const { position } = useContext(StatePositionContext);

  //COMING FROM CAREER PAGE ( CPID )
  const query = new URLSearchParams(useLocation().search);
  const cpid = query.get('cpid');

  //SELECT HEADER FROM POSITION AND CPID
  const logoPath = position?.branding?.files[0]?.signedRequest;
  const { page, header } = selectPageWithHeader(position.pages, cpid);

  const brandColor = position?.branding?.brandColor;
  const foreFrontColor =
    header && header.backgroundType === 'solid'
      ? getInvertedColor(brandColor)
      : '#fff';

  const pageUrlPath = pageUrl(page, position.organization);
  const cpRoot = pageUrl(null, position.organization);

  return (
    <>
      {header && (
        <StyledHeader
          backgroundType={header.backgroundType}
          backgroundImageUrl={
            header.files && header.files[0]?.signedRequest
          }
        >
          {header.backgroundType === 'video' && (
            <BackgroundVideo video={header.backgroundVideo} />
          )}
          <div>
            <div className="header-top-row">
              {pageUrlPath ? (
                <a className="arrow-back" href={pageUrlPath}>
                  <Icon icon="arrow_large" color={foreFrontColor} />
                </a>
              ) : (
                <div></div>
              )}
              {cpRoot ? (
                <a className="header-logo" href={cpRoot}>
                  {logoPath ? (
                    <img src={logoPath} alt="company logo" />
                  ) : (
                    <>{position?.organization?.name}</>
                  )}
                </a>
              ) : (
                <div className="header-logo">
                  <img src={logoPath} alt="company logo" />
                </div>
              )}
              <div className="account">
                {isAuthenticated ? (
                  <Link
                    to="/account"
                    style={{ color: foreFrontColor }}
                  >
                    {t('common.account')}
                  </Link>
                ) : (
                  <LoginButton
                    tiny
                    transparent
                    style={{ color: foreFrontColor }}
                  >
                    {t('common.login')}
                  </LoginButton>
                )}
              </div>
            </div>
            <div className="header-content">
              <div className="header-inner">
                <h1 className="main-title">{header.title}</h1>
                <p className="sub-title">{header.subTitle}</p>
              </div>
            </div>
          </div>
          {/* <pre>{JSON.stringify(data, 0, 1)}</pre> */}
        </StyledHeader>
      )}
    </>
  );
};

export default CareerPageHeader;
