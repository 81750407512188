import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import ProtectedRoute from '../components/ProtectedRoute';

import {
  Frontpage,
  Account,
  Application,
  LinkedInAuth,
  Closed,
} from './views';
import { NoMatch } from '../components/';

import TopBar from './components/TopBar/TopBar';
import { PositionProvider } from './context/PositionContext';
import Footer from './components/Footer';
import { ApplicationProvider } from './views/Application/ApplicationContext';
import TagManager from 'react-gtm-module';

const Layout = () => {
  // GTM initialize
  useEffect(() => {
    TagManager.initialize({
      gtmId: process.env.REACT_APP_GTM || 'GTM-MVXSJWX',
      dataLayerName: 'ApplicationFormDataLayer',
    });
  }, []);

  return (
    <PositionProvider>
      <ApplicationProvider>
        <Router>
          <Switch>
            {/* To disable topbar in this path */}
            <Route exact path="/position/:positionId/closed" />
            <Route exact path="*" component={TopBar} />
          </Switch>
          <Switch>
            <Route
              exact
              path="/position/:positionId/closed"
              component={Closed}
            />
            <Route
              exact
              path="/linkedInAuth"
              component={LinkedInAuth}
            />
            <Route
              exact
              path="/position/:positionId"
              component={Application}
            />

            <ProtectedRoute path="/account" component={Account} />
            <ProtectedRoute exact path="/" component={Frontpage} />
            <Route path="*">
              <NoMatch />
            </Route>
          </Switch>
          <Switch>
            <Route exact path="/position/:positionId/closed" />
            <Route exact path="*" component={Footer} />
          </Switch>
        </Router>
      </ApplicationProvider>
    </PositionProvider>
  );
};

export default Layout;
