import React, { useReducer, createContext } from "react";

export const StatePositionContext = createContext();
export const DispatchPositionContext = createContext();

//context for the loaded position - deload when leaving the position page.
//used to build forms and show organization name
const positionReducer = (state, action) => {
  switch (action.type) {
    case "init":
      return {
        ...state,
        isLoading: true,
      };
    case "position_load_success":
      //set explicit branding colors here
      return {
        ...state,
        isLoading: false,
        loadedPosition: true,
        position: action.payload,
      };
    case "position_load_error":
      return {
        isLoading: false,
        loadedPosition: false,
        isError: true,
        errorMessage: action.payload,
      };
    case "position_clear":
      return initialPosition;
    default:
      return { ...state };
  }
};

const initialPosition = {
  loadedPosition: false,
  isLoading: false,
  isError: false,
  errorMessage: "",
  position: {
    //the position data
  },
};

export const PositionProvider = ({ children }) => {
  const [state, dispatch] = useReducer(positionReducer, initialPosition);
  return (
    <DispatchPositionContext.Provider value={dispatch}>
      <StatePositionContext.Provider value={state}>
        {children}
      </StatePositionContext.Provider>
    </DispatchPositionContext.Provider>
  );
};
