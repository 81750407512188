import React, { useContext, useEffect, useState } from 'react';

import { StatePositionContext } from '../../context/PositionContext';
import { StateUserContext } from '../../../context/UserContext';
import { useLocation } from 'react-router-dom';
import { GenericTopBar } from '../../../components';
import { updateCandidate } from '../../../api/endpoints/candidate';

const TopBar = () => {
  const { position, loadedPosition } = useContext(
    StatePositionContext
  );
  const { user, isAuthenticated } = useContext(StateUserContext);
  const [useOrgLogo, setUseOrgLogo] = useState(false);
  const location = useLocation();

  useEffect(() => {
    //check location
    if (location.pathname.includes('position')) {
      setUseOrgLogo(true);
    } else {
      setUseOrgLogo(false);
    }
  }, [location]);
  return (
    <GenericTopBar
      user={user}
      onLanguageChange={async (language) => {
        //change candidate language
        try {
          const request = { body: { language: language } };
          await updateCandidate(request);
        } catch (err) {
          console.log(err);
        }
      }}
      loadedPosition={loadedPosition}
      position={position}
      orgPage={useOrgLogo && loadedPosition}
      orgName={
        useOrgLogo && loadedPosition
          ? position.organization.name
          : null
      }
      orgUrl={
        useOrgLogo && loadedPosition
          ? position.organization.organizationUrl
          : null
      }
      includeAccount={true}
      isAuthenticated={isAuthenticated}
    />
  );
};
export default TopBar;
