import React from "react";
import { Grid, Row, Col } from "../../../../../components/Grid";
import ShortForm from "./ShortForm/ShortForm";

const ApplicationForm = () => {
  return (
    <Grid>
      <Row mt={100}>
        <Col size={4} collapse="sm"></Col>
        <Col size={16} break="sm">
          <ShortForm />
        </Col>
        <Col size={4} collapse="sm"></Col>
      </Row>
    </Grid>
  );
};

export default ApplicationForm;
