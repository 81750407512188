export const initialExperience = {
  experience: {
    workplace: "",
    title: "",
    workplaceDescription: "",
    fromYear: "",
    toYear: "",
    fromMonth: "",
    toMonth: "",
    currentWorkplace: false,
    tasks: [""],
    contactName: "",
  },
  education: {
    institution: "",
    level: "",
    programme: "",
    fromYear: "",
    fromMonth: "",
    toYear: "",
    toMonth: "",
  },
};

const application = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  country: "",
  city: "",
  mainTitle: "",
  otherTitles: [],
  yearsExperience: 0,
  about: "",
  files: [],
  externalProfileImage: null,
  addFiles: [],
  removeFiles: [],
  links: {
    linkedin: "",
    other: "",
  },
  experience: [initialExperience["experience"]],
  education: [initialExperience["education"]],
  competencies: {},
  languages: {},
};

export const initialApplication = {
  isLoading: false,
  isParsing: false,
  isError: false,
  errorMessage: "",
  isSaved: true,
  isLoggedIn: false,
  emailExisting: false,
  dismissedEmailExisting: false,
  isSubmitModal: false,
  isSubmitted: false,
  isSubmitting: false,
  acceptedTerms: false,
  isValid: false,
  isUploadingFile: false,

  application: application,
};
