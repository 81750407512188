import React, { useContext } from 'react';
import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import {
  submitPrivateApplication,
  submitPublicApplication,
  updateCandidateApplication,
} from '../../../../../api/endpoints/candidate';
import {
  Button,
  CheckboxWidthLabel,
  Icon,
} from '../../../../../components';
import { Col, Grid, Row } from '../../../../../components/Grid';
import { DispatchErrorContext } from '../../../../../context/ErrorContext';
import { DispatchMessageContext } from '../../../../../context/MessageContext';
import { DispatchUserContext } from '../../../../../context/UserContext';

import { StatePositionContext } from '../../../../context/PositionContext';
import { ApplicationContext } from '../../ApplicationContext';
import { validateApplication } from '../../validationSchema';
import Terms from '../Terms';
import {
  checkExistingEmail,
  modifyValuesBeforeInsert,
} from '../util';

const SubmitApplication = () => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(ApplicationContext);

  const { position, loadedPosition } = useContext(
    StatePositionContext
  );

  //USER STATE
  const userDispatch = useContext(DispatchUserContext);

  //Error dispatch
  const messageDispatch = useContext(DispatchMessageContext);

  //Error dispatch
  const errorDispatch = useContext(DispatchErrorContext);

  const handleTermsAccept = () => {
    dispatch({ type: 'update_accept_terms' });
  };

  const handleSubmit = async () => {
    // Check for validity

    try {
      //Validate the application
      await validateApplication(state.application, position, t);

      //Check for existing user
      const exists = await checkExistingEmail(
        state.application.email,
        true,
        state.isLoggedIn,
        state.dismissedEmailExisting,
        () => {
          dispatch({ type: 'set_email_existing', payload: true });
        }
      );
      if (exists) throw new Error('Please login before submitting');
      dispatch({ type: 'submit_application_init' });

      //modify the application before submit
      const modifiedApplicationObjectBeforeSave =
        modifyValuesBeforeInsert(state.application, false);

      //Request body
      const request = {
        body: modifiedApplicationObjectBeforeSave,
        selector: position.uuid,
      };

      //Submit the application
      if (state.isLoggedIn) {
        //submit logged in
        const response = await submitPrivateApplication(request);
        if (!response.success) throw new Error(response.data?.data);
      } else {
        //submit not logged in
        const response = await submitPublicApplication(request);
        if (!response.success) throw new Error(response.data?.data);
      }

      //Submit ga event
      TagManager.dataLayer({
        dataLayer: {
          event: 'application_submit_success',
          positionId: position.id,
          positionName: position.title,
          organizationId: position.organization.id,
          organizationName: position.organization.name,
        },
        dataLayerName: 'ApplicationFormDataLayer',
      });

      //Go to confirmation
      dispatch({ type: 'submit_application_success' });
    } catch (err) {
      console.log(err);
      dispatch({ type: 'set_error', payload: err.message });
      errorDispatch({ type: 'set_error', payload: err.message });
    }
  };

  /**
   * Save application state in server if logged in
   * @returns void
   */
  const handleSave = async () => {
    try {
      //Do not persist if not logged in
      if (!state.isLoggedIn) return;

      //Validate the application
      await validateApplication(state.application, position, t);

      //Convert to useable object
      const modifiedApplicationObjectBeforeSave =
        modifyValuesBeforeInsert(state.application, false);

      //Make update request
      const request = {
        body: modifiedApplicationObjectBeforeSave,
        selector: position.uuid,
        params: { includeSignedRequest: true },
      };

      const response = await updateCandidateApplication(request);

      if (!response.success) throw new Error(response.data?.data);

      messageDispatch({
        type: 'set_message',
        payload: t('common.saved'),
      });

      dispatch({
        type: 'application_loaded',
        payload: { applicationData: response.data },
      });

      // if successful and logged in, it means the Candidate was updated as well.
      if (state.isLoggedIn) {
        userDispatch({
          type: 'updateUserFields',
          payload: modifiedApplicationObjectBeforeSave?.personal,
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({ type: 'set_error', payload: err.message });
      errorDispatch({ type: 'set_error', payload: err.message });
    }
  };

  return (
    <Grid>
      <Row mt={20}>
        <Col size={4} collapse="sm"></Col>
        <Col size={16} break="sm">
          <CheckboxWidthLabel
            type="checkbox"
            alignToTop
            checked={state.acceptedTerms}
            onChange={handleTermsAccept}
          >
            {loadedPosition && (
              <Terms organization={position.organization} />
            )}
          </CheckboxWidthLabel>
        </Col>
      </Row>
      <Col size={4} collapse="sm"></Col>

      <Row mt={40}>
        <Col size={4} collapse="sm"></Col>
        <Col vCenter hCenter size={16} break="sm">
          {/* {state.isLoggedIn && (
            <Button
              transparent
              urlLike
              secondary
              onClick={handleSave}
              disabled={
                state.isSaved ||
                state.isUploadingFile ||
                state.isParsing
              }
            >
              {t('common.save')}
            </Button>
          )} */}
          <Button
            // disabled={!state.isValid || state.isSubmitting}
            useStyleVars
            disabled={
              !state.acceptedTerms ||
              state.isUploadingFile ||
              state.isParsing
            }
            primary
            onClick={handleSubmit}
          >
            {state.isValid && <Icon icon="checkmark" />}
            <span style={{ color: 'inherit' }}>
              {t('application_form.submit_label')}
            </span>
          </Button>
        </Col>
        <Col size={4} collapse="sm"></Col>
      </Row>
    </Grid>
  );
};

export default SubmitApplication;
