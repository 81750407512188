import {
  getOpenApplications,
  getPublicPosition,
  checkCandidateConsent,
} from '../../../api/endpoints/candidate';
import {
  getRecentVisitedApplication,
  localGetAndParseApplication,
} from '../../../utils/localSave';

import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Grid, Row, Col } from '../../../components/Grid';
import ConsentModal from '../../components/ConsentModal';
import ApplicationList from '../../components/ApplicationList';

/**
 * The frontpage calls the server to check if candidate needs to reaffirm consent. Possible multiple consent
 * If no consent is needed then we check if candidate has ONE open application, if that is the case then the candidate
 * is redirected to the application form. If not a list of all candidate applications are displayed.
 * @returns jsx
 */

const Frontpage = () => {
  //Redirect to open applications if any
  const [positionRedirectId, setpositionRedirectId] = useState(false);
  const [consents, setConsents] = useState([]);

  useEffect(() => {
    const redirectIfOpen = async () => {
      try {
        //check if consent is needed!
        const consentResponse = await checkCandidateConsent();
        if (consentResponse.success) {
          if (consentResponse.data.length > 0) {
            //consent is needed
            setConsents(consentResponse.data);
          }
        }

        //get latest visit from local
        const recentVisit = getRecentVisitedApplication();
        if (recentVisit) {
          setpositionRedirectId(recentVisit);
        } else {
          const openApplication = await getOpenApplications({
            params: { canApply: 1 },
          });
          const { success, data } = openApplication;

          if (
            success &&
            data[0] &&
            data[0].position &&
            data[0].position.status === 'active' && //only redirect to active positions!
            data.length === 1 //only redirect if there is only one open application ( else show list of applications )
          ) {
            setpositionRedirectId(data[0].position.uuid); //only redirect if position is active.
          } else {
            //check if has a public position open.
            const localSaves = localGetAndParseApplication();

            for (const localSaveId in localSaves) {
              //check if this position exists before redirecting.
              const request = { selector: localSaveId };
              const position = await getPublicPosition(request);

              if (
                position.success &&
                position.data &&
                position.data.status === 'active'
              ) {
                //only redirect if position is active
                setpositionRedirectId(localSaveId);
                break;
              }
            }
          }
        }
      } catch (err) {
        console.log(err);
      }
    };
    redirectIfOpen();
  }, []);

  return (
    <Grid>
      <Row>
        <Col>
          {consents.length > 0 && (
            <ConsentModal
              consents={consents}
              setConsents={setConsents}
            />
          )}
          {positionRedirectId && consents.length === 0 ? (
            <Redirect to={`/position/${positionRedirectId}`} />
          ) : (
            <ApplicationList />
          )}
        </Col>
      </Row>
    </Grid>
  );
};

export default Frontpage;
