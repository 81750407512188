import axios from 'axios';
import { parseRequest } from '../utilities/queries';
import {
  errorResponseHandler,
  responseHandler,
} from '../utilities/response';

export const getOpenApplications = async (request) => {
  const { params } = parseRequest(request);
  try {
    const data = await axios.get(`candidate/application/${params}`);
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

export const deleteCandidateApplication = async (request) => {
  const { selector } = parseRequest(request);
  try {
    const data = await axios.delete(
      `candidate/application/${selector}`
    );
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

/**
 * Get candidate application(s), by positionUuid
 * @param {object} request  { selector : positionUuid }
 * @returns
 */
export const getCandidateApplication = async (request) => {
  const { selector } = parseRequest(request);
  try {
    const data = await axios.get(`candidate/application/${selector}`);
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

export const getPublicPosition = async (request) => {
  const { selector, params } = parseRequest(request);
  try {
    const data = await axios.get(
      `position/public/${selector}/${params}`
    );
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

// Logged in candidate is saving data to server!
// Will create and return a new draft_hidden application if it does not exist!
export const updateCandidateApplication = async (request) => {
  const { selector, params, body } = parseRequest(request);
  try {
    const data = await axios.put(
      `candidate/application/${selector ? selector : ''}${
        params ? '/' + params : ''
      }`,
      body
    );
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

/**
 * Submit the application for a non existing user. Create candidate / applicant and application if succesful
 * @param {object} request { selector : positionuuid, body : { personal, application }}
 * @returns
 */
export const submitPublicApplication = async (request) => {
  const { selector, body } = parseRequest(request);
  // console.log(selector, body);
  try {
    const data = await axios.post(
      `candidate/application/public/${selector}`,
      body
    );
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

/**
 * Submit an application when candidate is logged in
 * @param {object} request  { selector : positionuuid, body : { personal, application }}
 * @returns
 */
export const submitPrivateApplication = async (request) => {
  const { selector, body } = parseRequest(request);
  try {
    const data = await axios.post(
      `candidate/application/${selector}`,
      body
    );
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

//Delete self ( candidate )
export const deleteCandidate = async (request) => {
  try {
    const data = await axios.delete(`candidate`);
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

//Update self ( candidate )
export const updateCandidate = async (request) => {
  const { body } = request;
  try {
    const data = await axios.put(`candidate`, body);
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

//check if candidate exist, supply email as params
export const checkCandidateEmail = async (request) => {
  const { params } = parseRequest(request);
  try {
    const data = await axios.get(`candidate/check${params}`);
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

//Check if consent is needed
export const checkCandidateConsent = async () => {
  try {
    const data = await axios.get(`candidate/consent`);
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

//Give consent ( params : { organizationId : number } })
export const giveCandidateConsent = async (request = {}) => {
  const { params } = parseRequest(request);
  try {
    const data = await axios.post(`candidate/consent${params}`);
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};
