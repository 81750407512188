import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { VerticalDivider } from "../../../../../components";
import { Col, Grid, Row } from "../../../../../components/Grid";
import { Typography } from "../../../../../components/Typography";
import prependUrl from "../../../../../utils/prependUrl";
import { StatePositionContext } from "../../../../context/PositionContext";

const ApplicationHeader = () => {
  // LANGUAGE
  const { t } = useTranslation();

  // POSITION STATE
  const { position } = useContext(StatePositionContext);

  return (
    <>
      <Grid>
        <Row mt={100}>
          <Col hCenter>
            <Typography tag="h1" color="rgb(85, 85, 85)">
              {position.unsolicited ? t("common.application.unsolicited_applications") : position.title}
            </Typography>
            {!(!position.description || position.description.length === 0) && (
              <Typography tag="p" mt={10}>
                {position.description}
              </Typography>
            )}
          </Col>
        </Row>
        <Row mt={20}>
          <Col hCenter vCenter>
            {position.descriptionUrl && (
              <>
                <Typography
                  tag="a"
                  colorDark
                  target="_blank"
                  href={prependUrl(position.descriptionUrl)}
                >
                  {t("application_form.position_description_url")}
                </Typography>
                {position?.files?.find(
                  (file) => file.docType === "positionDescription"
                ) && <VerticalDivider margin={24} />}
              </>
            )}
            {position?.files?.find(
              (file) => file.docType === "positionDescription"
            ) && (
                <Typography
                  useStyleVars
                  tag="a"
                  href={
                    position?.files?.find(
                      (file) => file.docType === "positionDescription"
                    ).signedRequest
                  }
                  button
                  mt={0}
                  style={{ display: "inline-block" }}
                >
                  <Typography inherit tag="span" bold mr={10}>
                    {t("common.pdf")}
                  </Typography>
                  {t("common.download")}
                </Typography>
              )}
          </Col>
        </Row>
      </Grid>
    </>
  );
};

export default ApplicationHeader;
