import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import {
  getCandidateApplication,
  getPublicPosition,
} from '../../../api/endpoints/candidate';
import { BoxLoader } from '../../../components';
import NavigateAwayPrompt from '../../../components/NavigateAwayPrompt';
import { DispatchErrorContext } from '../../../context/ErrorContext';
import { StateUserContext } from '../../../context/UserContext';

import {
  clearGenericSessionStorage,
  getGenericSessionStorage,
  setRecentVisitedApplication,
} from '../../../utils/localSave';

import {
  DispatchPositionContext,
  StatePositionContext,
} from '../../context/PositionContext';
import { ApplicationContext } from './ApplicationContext';
import AccountExistsModal from './components/AccountExistsModal';
import ApplicationConfirmation from './components/ApplicationConfirmation';

import ApplicationForm from './components/ApplicationForm';
import ApplicationHeader from './components/ApplicationHeader';
import CareerPageHeader from './components/CareerPageHeader';
import { StyledButtonVars } from './components/style';
import SubmitApplication from './components/SubmitApplication';
import { modifyValuesBeforeInsert } from './components/util';

import TagManager from 'react-gtm-module';

const Application = () => {
  const { t } = useTranslation();

  //POSITION
  const { positionId } = useParams();

  //HISTORY
  useEffect(() => {
    setRecentVisitedApplication(positionId);
  }, [positionId]);
  const history = useHistory();

  //LOGGED IN STATE
  const { user, isAuthenticated } = useContext(StateUserContext);

  //POSITION STATE
  const { position, isLoading: positionIsLoading } = useContext(
    StatePositionContext
  );
  const positionDispatch = useContext(DispatchPositionContext);

  //APPLICATION STATE
  const { state, dispatch } = useContext(ApplicationContext);

  //ERROR AND MESSAGES
  const errorDispatch = useContext(DispatchErrorContext);

  useEffect(() => {
    let isSubscribed = true;

    /**
     * Load position and set in state!
     */
    const initPosition = async () => {
      try {
        positionDispatch({ type: 'init' });
        const response = await getPublicPosition({
          selector: positionId,
          params: { includeSignedRequest: 1 },
        });

        //Position exists but is closed or does not exist
        if (response.limited || !response.success)
          history.push(`/position/${positionId}/closed`);

        if (isSubscribed) {
          positionDispatch({
            type: 'position_load_success',
            payload: response.data,
          });
        }
        return response.data;
      } catch (err) {
        errorDispatch({ type: 'set_error', payload: err });
      }
    };

    /**
     * load application state from server or save new application if is authenticated
     * If not try to get local storage state
     */
    const initApplication = async (position) => {
      try {
        if (isSubscribed) {
          dispatch({ type: 'init' });
          if (isAuthenticated) {
            let existingApplication = { ...user };
            //TRY TO GET ONLINE APPLICATION IF AUTHENTICATED INCLUDE USER INFO
            const response = await getCandidateApplication({
              selector: position.uuid,
            });
            if (response.success) {
              existingApplication = {
                ...existingApplication,
                ...modifyValuesBeforeInsert(response.data, true),
              };
            }
            //SET IN STATE!
            dispatch({
              type: 'application_loaded',
              payload: { applicationData: existingApplication },
            });
          } else {
            //GET LINKEDIN DATA
            const linkedInDataInSessionStorage =
              JSON.parse(getGenericSessionStorage('linkedin_data')) ||
              {};

            //TODO LOCAL PERSISTANCE???
            dispatch({
              type: 'application_loaded',
              payload: {
                applicationData: modifyValuesBeforeInsert(
                  linkedInDataInSessionStorage,
                  true
                ),
              },
            });
          }
        }
      } catch (err) {
        console.log('error initializing application', err);
      }
    };

    const initialize = async () => {
      //SET USER IN STATE
      if (isAuthenticated) dispatch({ type: 'set_is_logged_in' });

      if (isSubscribed) {
        //LOAD POSITION
        const loadedPosition = await initPosition();

        //LOAD EXISTING APPLICATION DATA!
        await initApplication(loadedPosition);
      }
    };

    if (isSubscribed) {
      initialize();
    }

    return () => {
      isSubscribed = false;
      clearGenericSessionStorage('linkedin_data');
      positionDispatch({ type: 'position_clear' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, positionId]);

  // GTM send datalayer
  useEffect(() => {
    if (position.id) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'application_form_view',
          positionId: position.id,
          positionName: position.title,
          organizationId: position.organization.id,
          organizationName: position.organization.name,
        },
        dataLayerName: 'ApplicationFormDataLayer',
      });
    }
  }, [position]);

  if (state.isSubmitted) return <ApplicationConfirmation />;

  return (
    <>
      <StyledButtonVars
        accentColor={position?.branding?.accentColor}
        brandColor={position?.branding?.brandColor}
      />
      {position?.pages?.length > 0 && (
        <CareerPageHeader />
      )}
      <NavigateAwayPrompt
        isSaved={state.isSaved}
        isLoggedIn={state.isLoggedIn}
      />
      {(state.isLoading || positionIsLoading) && (
        <BoxLoader message={t('common.loading')} />
      )}
      {state.emailExisting && <AccountExistsModal />}

      {!state.isLoading && !positionIsLoading && (
        <ApplicationHeader />
      )}
      {!state.isLoading && !positionIsLoading && <ApplicationForm />}
      {!state.isLoading && !positionIsLoading && (
        <SubmitApplication />
      )}
    </>
  );
};

export default Application;
