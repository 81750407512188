import { useAuth0 } from '@auth0/auth0-react';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Button, Icon } from '../../../../../components';
import LogoutButton from '../../../../../components/LogoutButton';
import {
  StyledH1,
  StyledH3,
  StyledP,
} from '../../../../../components/Typography';
import { StatePositionContext } from '../../../../context/PositionContext';
import {
  StyledClosed,
  StyledLeftContainer,
  StyledRightContainer,
} from '../../../Closed/style';
import CompanyLogo from '../../../../components/CompanyLogo';
import { clearRecentVisitedApplication } from '../../../../../utils/localSave';

const ApplicationConfirmation = () => {
  const { t } = useTranslation();
  // const { isAuthenticated } = useContext(StateUserContext);
  const { isAuthenticated } = useAuth0();
  const { position } = useContext(StatePositionContext);
  const history = useHistory();

  useEffect(() => {
    //this component is rendered when an application has been submitted
    //So we clear recent visited
    clearRecentVisitedApplication();
  }, []);

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        zIndex: 1,
      }}
    >
      <StyledClosed>
        <StyledLeftContainer>
          <div>
            <CompanyLogo
              name={position?.organization?.name}
              path={position?.organization?.organizationUrl}
              logoPath={position?.branding?.files[0]?.signedRequest}
            />
            <StyledH1 light customSize={42} style={{ marginTop: 50 }}>
              {t(
                'application_form.confirmation.success',
                'Thank you we have received your application'
              )}
            </StyledH1>
            {isAuthenticated ? (
              <>
                <Button
                  onClick={() => {
                    history.push('/account');
                  }}
                  style={{ marginTop: 30 }}
                  secondary
                >
                  {t('common.account')}
                </Button>
                <LogoutButton red secondary>
                  {t('common.logout')}
                </LogoutButton>
              </>
            ) : (
              <>
                <StyledH3 light>
                  {t(
                    'application_form.confirmation.account_created',
                    'You should receive an email shortly with information about your account.'
                  )}
                </StyledH3>
                <StyledP light>
                  {t(
                    'application_form.confirmation.account_created_two',
                    'To give you full control over your data a HiTalento account has been created. Check your email for more information.'
                  )}
                </StyledP>
              </>
            )}
          </div>
        </StyledLeftContainer>
        <StyledRightContainer>
          <Icon icon="application_submitted" />
          <div>
            <Icon icon="logo_with_name" />
          </div>
        </StyledRightContainer>
      </StyledClosed>
    </div>
  );
};

export default ApplicationConfirmation;
