export const candidateTheme = {
  colors: {
    primary: "#EBF3FF",
    secondary: "#F5F9FF",
    blue: "#F5F9FF",
    highlight: "#FF385C",
    red: "#FF385C",
    green: "#98C269",
    lgrey: "#EFEFEF",

    lgreytwo: "#FBFBFB",
    mgrey: "#CDCDCD",
    dgrey: "#555555",
    vdgrey: "#313230",
    primaryButtonColor: "#555555",
  },
  breakpoints: {
    xs: "320px",
    sm: "568px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
    xxl: "1400px",
  },
  grid: {
    gutter: "15px",
  },
  fonts: {
    fontFamily: '"Work Sans", Arial, Helvetica, sans-serif',
    smallSize: "9px",
    defaultSize: "14px", //update
    labelSize: "16px", // updates to 16px
    bylineSize: "12px", // updates to 12px
    buttonStandardSize: "14px",
  },
  inputs: {
    borderSize: "2px",
  },
};
